<template>
  <div class="lo-t-device-detail">
    <device-card show-bg :detail="topDetail" />
    <!-- <div class="other-device-situation-title">其余设备情况</div>
    <device-card
      v-for="(item, index) in list"
      :key="'qysblb' + index"
      :detail="item"
    /> -->
  </div>
</template>

<script>
import deviceCard from "./deviceCard.vue";
import { getLoTMonitorListUrl, getSceneParamUrl } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
export default {
  name: "loTDeviceDetail",
  components: {
    deviceCard,
  },
  data() {
    return {
      list: [],
      topDetail: {
        deviceName: "",
        deviceNum: "",
        productId: "",
        productName: "",
        status: void 0,
        statusName: "",
        updateTime: "",
        value: "",
      },
      params: "",
      deviceId: "",
      timer: null
    };
  },
  async created() {
    await this.getSceneParam();
    this.handleDevice();
    this.getList();
    this.startReckonTime();
  },
  methods: {
    startReckonTime() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(this.getList, 1000 * 30);
    },
    async getSceneParam() {
      let paramId = getHashParam("paramId");
      const res = await this.$axios.get(getSceneParamUrl, {
        params: {
          paramId,
        },
      });
      if (res.code === 200 && res.success) {
        this.params = res.data ? res.data : {};
      }
    },
    handleDevice() {
      if (this.params && this.params.split("=")) {
        this.deviceId = this.params.split("=")[1];
      }
    },
    getList() {
      this.$axios
        .get(getLoTMonitorListUrl, {
          params: {
            // deviceSN: this.$route.query.paramId || void 0,
            queryMode: "qrmode",
            pageSize: 50,
          },
        })
        .then((res) => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.records = Array.isArray(res.data.records)
              ? res.data.records
              : [];
            res.data.records.forEach((item) => {
              item.name = item.deviceName + "（" + item.deviceNum + "）";
            });
            const index = res.data.records.findIndex(
              (item) => item.deviceNum === this.deviceId
            );
            index >= 0 &&
              Object.assign(
                this.topDetail,
                res.data.records.splice(index, 1)[0]
              );
            this.list = res.data.records;
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.lo-t-device-detail {
  min-height: 100%;
  background-color: #fafafa;
  box-sizing: border-box;
  padding: 20px 30px;
  .device-card {
    margin-bottom: 20px;
  }
}
.other-device-situation-title {
  height: 80px;
  background: linear-gradient(90deg, #367bf4 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 16px;
  color: #ffffff;
  font-size: 28px;
  line-height: 28px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
</style>
